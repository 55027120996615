
import Link from "next/link";
import SvgJsx from "../templateux/svg/svg-jsx";

export default function ShowAlertBanner({
    user_name
}) {


    return <>
                <div className=" py-5  text-center border-b 
                                          border-gray-700 bg-red-900
                                          ">
                            <div className="font-bold text-lg sm:text-2xl 
                                          text-red-500
                                        ">
                              The show is live!
                            </div>
                            <div className="mt-2 text-left flex">
                              <Link
                                href={`/${user_name?.toLowerCase()}/chat`}
                                className="mx-auto justify-center flex flex-0 cursor-pointer font-bold text-md sm:text-lg px-4 py-1 rounded-full
                                            
                                            bg-red-500 text-white hover:bg-red-400">

                                <div className="flex-0 flex items-center content-center ">
                                <div className="flex-0">
                                  Join live chat!
                                </div>
                                <div className="flex-0 -mr-2 ">
                                  <SvgJsx 
                                      type='fill'
                                      icon='chevron-right-sm'
                                      className={` text-white h-6 w-6`}
                                      title="Premium Feature"
                                  />
                                </div>
                                </div>

                              </Link>
                            </div>
                          </div>
    
            </>;

}