import { cleanGiphyStr } from "@/lib/utils/cleanGiphyStr";
import { hasData } from "@/lib/utils/hasData";
import GifPicker, { Theme } from 'gif-picker-react';
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import Tabs from "../templateux/tabs";
import Image from 'next/legacy/image'
import ProfileArray from "../profile/item/profile-array";
import { MarkdownContent } from "../templateux/markdown-preview";
import ProfileStats from "../profile/profile-stats";
import { getChatHeadline } from "@/lib/utils/getChatHeadline";
import { Dialog, Transition } from "@headlessui/react";
import Uploads from "./uploads";
import Giphy from "../profile/chat/giphy";
import ProfileLinks from "../profile/profile-links";
import ChatDraw from "./ChatDraw";
import GatingContributor from "../templateux/gating/contributor";
import { config } from "@/lib/config";
import Dalle from "../draw/dalle";
import { ProfileDescription } from "../profile/profile-description";
import TimestampForm from "../profile/timestamp/timestamp-form";
import toast from "react-simple-toasts";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import ProfileFollowWelcome from "../profile/item/profile-follow-welcome";
import { signIn } from "next-auth/client";
import ToggleButton from "../templateux/toggle/toggle-button";

export const ChatModal = ({
     isChatOpen
    ,closeModal
    ,CloseButton
    ,modal_panel
    ,set_modal_panel
    ,profiledata
    ,userdata
    ,openModalChooser
    ,msg
    ,isAdmin
    ,navtype
    ,helptype
    ,nodata
    ,members_chat
    ,members_listeners
    ,modal_length
    ,chatsingleuserdata
    ,chatsingleuserdataisLoading
    ,chatsingleuserdataisError
    ,chatmultipleuserdata 
    ,chatmultipleuserdataisLoading 
    ,chatmultipleuserdataisError 
    ,sendmsg
    ,channel_id
    ,router
    ,state_following_them
    ,set_state_following_them
    ,state_following_them_status
    ,set_state_following_them_status
    ,session
    ,changes
    ,set_changes

}) => {

    const { isConfirmed } = useConfirm();

    const [hide_welcome, set_hide_welcome] = useState(false)

    useEffect(()=>{
        set_hide_welcome((hasData(localStorage.getItem(`chat-${profiledata?.id}`)) && localStorage.getItem(`chat-${profiledata?.id}`) == profiledata?.chat_rules_timestamp))
    },[
        localStorage.getItem(`chat-${profiledata?.id}`)
    ])

    const toggleHide = () => {
        set_hide_welcome(prev=>{
            if (hasData(localStorage.getItem(`chat-${profiledata?.id}`)) && localStorage.getItem(`chat-${profiledata?.id}`) == profiledata?.chat_rules_timestamp) {
                localStorage.removeItem(`chat-${profiledata?.id}`);
                return false
            } else {
                localStorage.setItem(`chat-${profiledata?.id}`, (profiledata?.chat_rules_timestamp));
                closeModal();
                return true;
            }
        })
    }

    const followHandler = async (id,bol_following,user_name) => {

        var confirmed = true;
        if (bol_following) {
          //do confirmation check
          confirmed = await isConfirmed(`Are you sure you want to unfollow ${user_name}?`);
        }
        
        if (confirmed) {
          if (hasData(id)) {
            const res = await fetch(`/api/private/user-follow/insert?id=${id}`);;
            const json = await res.json()
            if (json) {
              // console.log("json",json)
              const { action, status } = json

              set_state_following_them_status(status);
             
              if (action>=1) { 
                await set_state_following_them(true)
                toast(`You are now following ${user_name}!`, { time: 3000, className: '', clickable: true, clickClosable: false });
              } else { 
                await set_state_following_them(false)
              } 
            }
          }
        }
        
      }
    
    const [canvas,set_canvas] = useState({
        width:config.draw.chatwidth, 
        height:config.draw.chatheight, 
        bgcolor:config.draw.bgcolor,
        brushcolor:config.draw.brushcolor,
        title:config.draw.title,
        src:"",
        id:"0",
        size:0
      })

    const [clock,set_clock] = useState(modal_panel.data)

    useEffect(()=>{
    set_clock(modal_panel.data)
    },[
    modal_panel.data
    ])


    return <>
                    <Transition appear show={isChatOpen} as={Fragment}>
                    <Dialog
                    as="div"
                    open={isChatOpen}
                    className="fixed inset-0 overflow-y-scroll bg-[rgb(0,0,0,0.85)]"
                    onClose={closeModal}
                    >
                    
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className=" inline-block max-w-3xl w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                        bg-gray-900 border-gray-700">
                            
                            <div className="items-center content-center">
                                <div className="flex-1">

                                {/* TIMESTAMP */}
                                {(["timestamp"]?.indexOf(modal_panel.id) > -1) &&
                                <>
                                <TimestampForm
                                    clock={clock}
                                    set_clock={set_clock}
                                    closeModal={closeModal}
                                    sendmsg={sendmsg}
                                    channel_id={channel_id}
                                    live_chat={true}
                                    post_id={0}
                                    host_id={profiledata?.id}
                                    content_id={profiledata?.broadcast_id}
                                    fetchurl={`/api/private/timestamp/insert-chat`}
                                 />
                                </>
                                }

                                {/* GIFS / UPLOAD */}
                                {(["gifs","upload","draw","generate"]?.indexOf(modal_panel.id) > -1) &&
                                <>
                                    

                                    
                                    <div className="flex items-center content-center mb-4">
                                        <div className='flex-1'>

                                            <Tabs
                                                tabarray={[
                                                        {text:"Gifs",value:"gifs",premium:"0"},
                                                        {text:"Upload",value:"upload",premium:"1"},
                                                        {text:"Draw",value:"draw",premium:"1"},
                                                        //{text:"Generate",value:"generate",premium:"1"}
                                                    ]}
                                                clickval={modal_panel.id}
                                                set_clickval={openModalChooser}
                                                tab_style="underline"
                                            />
                                            
                                       </div>
                                        <div className='flex-0'>
                                            <CloseButton />
                                        </div>
                                    </div>

                                    {modal_panel.id=='gifs' &&
                                        <div className="max-w-3xl">
                                            <Giphy
                                                onGifClick={(gif, e) => {
                                                    e.preventDefault();
                                                    //console.log("gif",gif)
                                                    sendmsg(e,channel_id,"message",{text:`![${gif.title}](${gif.images.fixed_width.url} =${gif.images.fixed_width.width}x${gif.images.fixed_width.height} "${gif.title}")`,emoji:2,bol_private:msg.bol_private},gif);
                                                    closeModal();
                                                    }}
                                                view='carousel'
                                                msg={hasData(msg.text) 
                                                        ? msg.text 
                                                        : cleanGiphyStr(profiledata?.chat_emoji_text)}
                                                profiledata={profiledata}
                                            />
                                        </div>
                                    }

                                    {modal_panel.id=='gifs2' &&
                                        <div className="max-w-3xl">
                                            <GifPicker 
                                                tenorApiKey={process.env.NEXT_PUBLIC_TENOR_API_KEY} 
                                                onGifClick={(TenorImage)=>{
                                                    console.log(TenorImage)
                                                }}
                                                autoFocusSearch
                                                width='100%'
                                                height='100%'
                                                theme={Theme.DARK}
                                            />
                                            <Giphy
                                                onGifClick={(gif, e) => {
                                                    e.preventDefault();
                                                    //console.log("gif",gif)
                                                    sendmsg(e,channel_id,"message",{text:`![${gif.title}](${gif.images.fixed_width.url} =${gif.images.fixed_width.width}x${gif.images.fixed_width.height} "${gif.title}")`,emoji:2,bol_private:msg.bol_private},gif);
                                                    closeModal();
                                                    }}
                                                view='carousel'
                                                msg={hasData(msg.text) 
                                                        ? msg.text 
                                                        : cleanGiphyStr(profiledata?.chat_emoji_text)}
                                                profiledata={profiledata}
                                            />
                                        </div>
                                    }

                                    {modal_panel.id=='upload' &&
                                        <>
                                            <GatingContributor
                                                show_condition={userdata?.contributor_level > 0}
                                            >
                                                <Uploads
                                                    sendmsg={sendmsg}
                                                    //formref={'msgform'}
                                                    closeModal={closeModal}
                                                    bol_private={msg.bol_private}
                                                    channel_id={channel_id}
                                                    set_clickval={openModalChooser}
                                                    set_canvas={set_canvas}
                                                    profiledata={profiledata}
                                                />
                                            </GatingContributor>
                                          
                                        </>
                                    }

                                    {modal_panel.id=='draw' &&
                                        <>
                                            <GatingContributor
                                                show_condition={userdata?.contributor_level > 0}
                                            >
                                            <ChatDraw
                                                router={router}
                                                sendmsg={sendmsg}
                                                closeModal={closeModal}
                                                bol_private={msg.bol_private}
                                                channel_id={channel_id}
                                                canvas={canvas}
                                                set_canvas={set_canvas}
                                                profiledata={profiledata}
                                                changes={changes}
                                                set_changes={set_changes}
                                            />
                                            </GatingContributor>
                                        </>
                                    }

                                    {modal_panel.id=='generate' &&
                                        <>
                                            <GatingContributor
                                                show_condition={userdata?.contributor_level > 0}
                                            >
                                            <Dalle
                                                router={router}
                                                sendmsg={sendmsg}
                                                closeModal={closeModal}
                                                bol_private={msg.bol_private}
                                                channel_id={channel_id}
                                                canvas={canvas}
                                                set_canvas={set_canvas}
                                                profiledata={profiledata}
                                                changes={changes}
                                                set_changes={set_changes}
                                            />
                                            </GatingContributor>
                                        </>
                                    }
                                
                                </>
                                }

                                {/* WELCOME LIKES STATS*/}
                                 {(["welcome","about","stats"]?.indexOf(modal_panel.id) > -1) &&
                                <>
                                    <div className="flex items-center content-center mb-4">
                                        <div className="flex-1">
                                        <Tabs
                                                    tabarray={
                                                        [
                                                            {text:"Welcome",value:"welcome"}
                                                            ,{text:"About",value:"about"}
                                                            ,{text:"Stats",value:"stats"}
                                                        ]
                                                    }
                                                    clickval={modal_panel.id}
                                                    set_clickval={openModalChooser}
                                                    tab_style="underline"
                                                />
                                        </div>
                                        <div className="mr-1">
                                        {!state_following_them &&
                                            <div className="flex">
                                            
                                                <ProfileFollowWelcome
                                                    classColor={`${state_following_them ? 'bg-gray-500' : 'bg-brand hover:bg-blue-600'}  text-white`}
                                                    onClick={()=>
                                                        {
                                                            if (!session) {
                                                                signIn()
                                                            } else {
                                                                followHandler(profiledata?.id,(state_following_them && state_following_them_status >= 1),profiledata?.user_name)
                                                            }
                                                            
                                                        }}
                                                    message={(state_following_them && state_following_them_status >= 1) 
                                                            ? `Following` 
                                                            : state_following_them && state_following_them_status < 1
                                                            ? 'Requested'
                                                            : `Follow ${profiledata?.user_name}`
                                                            } 
                                                    shrink={false}
                                                />
                                            
                                            </div>
                                            }
                                        </div>
                                        <div className='flex-0'>
                                            <CloseButton />
                                        </div>
                                    </div>
                                    <div className="w-full p-2  overflow-y-auto rounded-md border border-gray-700">

                                            {modal_panel.id=='welcome' &&
                                            <>
                                            
                                                <div className="flex w-full items-center content-center ">
                                                    <div className="flex-1">
                                                        <h1 className="font-bold text-lg ">
                                                        Welcome to {profiledata?.user_name}&apos;s Chat!
                                                        </h1>
                                                    </div>
                                                    {isAdmin &&
                                                    <div className="xs:flex items-center content-center">

                                                    
                                                        <div
                                                            className="flex-0 "
                                                            >
                                                            <Link
                                                                href={`/${profiledata?.user_name}/settings/chat?panel=userwelcome`}
                                                                className={`flex items-center content-center border px-1.5 py-0.5 rounded-md hover:shadow-sm 
                                                                        text-gray-500 border-gray-400 hover:border-gray-200 hover:text-gray-200
                                                                            `}> 
                                                                
                                                                
                                                                    
                                                                        edit
                                                                
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    }
                                                
                                                </div>
                                            
                                                
                                                <div className="w-full my-5">
                                                    <MarkdownContent
                                                        preview_content={profiledata?.chat_rules}
                                                    />
                                                    
                                                </div>
                                            </>
                                            }


                                            {modal_panel.id=='about' &&
                                                <div className="">

                                                    
                                                    <div className="mb-8">
                                                        <ProfileDescription
                                                        profiledata={profiledata}
                                                        isAdmin={isAdmin}
                                                        /> 
                                                    </div>
                                                    <div className="">

                                                        <ProfileLinks
                                                        profiledata={profiledata}
                                                        isAdmin={isAdmin}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {modal_panel.id=='stats' &&
                                            <>
                                            
                                                {profiledata?.broadcast_id  > 0 &&
                                                <>
                                                    <ProfileStats
                                                        ownerdata={profiledata} 
                                                        userdata={userdata}
                                                        isAdmin={isAdmin}
                                                        websocket_chatters={members_chat}
                                                        websocket_listeners={members_listeners}
                                                    />
                                                </>
                                                }
                                                {profiledata?.broadcast_id == 0 &&
                                                <div className="text-sm text-center pt-2 "
                                                    //onClick={(()=>console.log(player_state))}
                                                >
                                                    No active broadcast to show. 
                                                    
                                                </div>
                                                }

                                            </>
                                            }

                                            


                                    </div>

                                    
                                </>
                                }
                               
                                           
                                </div>
                                
                            </div>
                            
                            {/* IMAGES */}
                            {(['image','gif'].indexOf(modal_panel.id) > -1) &&
                            <>
                            
                            
                                
                                
                                
                               {/* IMAGE DATA*/}
                               
                                <>
                                {modal_panel.data}
                                </>
                             
                            </>
                            }
                            
                            {/* USERS */}
                            {(['users'].indexOf(modal_panel.id) > -1 && modal_panel.type == 'multiple') &&
                                <div className="mt-4 w-full content-center item-center flex">
                                    <div className="flex-1">
                                        {getChatHeadline(profiledata,members_chat)}
                                    </div>
                                    
                                    <div className='flex-0'>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-2 py-1 text-md font-medium text-white bg-blue-500 hover:bg-blue-400  border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                
                                </div>
                            }

                            
                            <div className="mt-4 w-full content-center item-center flex">
                            {['users','likes','reports'].indexOf(modal_panel.id) > -1 &&
                            
                            <ProfileArray 

                                itemdata={{data: modal_panel.type == 'multiple' 
                                            ? chatmultipleuserdata 
                                            : [chatsingleuserdata]
                                        }}
                                    
                                isLoading={modal_panel.type == "multiple" 
                                            ? chatmultipleuserdataisLoading 
                                            : chatsingleuserdataisLoading}

                                isError={modal_panel.type == "multiple" 
                                            ? chatmultipleuserdataisError 
                                            : chatsingleuserdataisError}

                                sum_items={modal_panel.type == "multiple" 
                                            ? modal_length 
                                            : 1}
                                userdata={userdata}
                                shrink={true}
                                ownerdata={profiledata}
                                nodata={nodata}
                                navtype={navtype}
                                helptype={helptype}
                            />
                            }

                            



                            

                            
                            </div>
                            

                            <div className="
                            ">
                            <div className="flex items-center content-center">
                                <div className="flex-1">
                                    {modal_panel.id=='gifs' &&
                                    <>
                                    <div className="mt-4 border-t pt-4 
                                            border-gray-700">
                                        <Link
                                            href='https://giphy.com'
                                            className={`hover:opacity-75 cursor-pointer`}
                                            target="_blank"
                                            rel="noreferrer">

                                            <Image
                                                src={`/images/socialmedia/giphy/dark.png`}
                                                alt={'Powered by GIPHY'}
                                                quality={100}
                                                width={200}
                                                height={26}
                                            />

                                        </Link>
                                    </div>
                                    </>
                                    }

                                {modal_panel.id=='welcome' &&
                                <>
                                <div className="mt-4 border-t pt-4 
                                            border-gray-700">
                                   
                                    <div className="flex items-center content-center">
                                        <div className="flex-0">
                                            <ToggleButton
                                                bol_item={hide_welcome ? "1" : "0"}
                                                toggle_text={`Hide this until it changes.`}
                                                size={'small'}
                                                clickEvent={()=> toggleHide()}
                                             />
                                        </div>
                                        <div className="flex-0 pl-2 cursor-pointer"
                                            onClick={()=> toggleHide()}
                                        >
                                           
                                            Hide this until it changes
                                           
                                        </div>
                                        
                                    </div> 
                                </div>
                                </>
                                }

                            


                               
                            


                                </div>
                               
                            </div>
                           
                            </div>
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>
    
    
    </>;
}