import { Fragment, useContext, useEffect, useState } from "react"
import { formatPlural } from "@/lib/utils/formatPlural"
import { hasData } from "@/lib/utils/hasData"
import AvatarFlair from "@/components/templateux/user/avatar-flair"
import ProfileMenuTipJarNew from "../item/profile-menu-tip-jar-NEW"
import { flairObject } from "@/lib/utils/flairObject"
import { config } from "@/lib/config"
import { calculateUsers } from "@/lib/utils/calculateUsers"
import ProfileFollowChat from "../item/profile-follow-chat"
import { useConfirm } from "@/lib/utils/hooks/useConfirm"
import toast from "react-simple-toasts"
import ChatFlair from "./chat-flair"
import Link from "next/link"
import { validTipJar } from "@/lib/utils/validTipJar"
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import AvatarFlairClean from "@/components/templateux/user/avatar-flair-clean"
import { chatauthor } from "@/components/ably/chatauthor"

function ChatUsersArrayVertical({
      members_chatters
     ,members_listeners
     ,sum_chatters
     ,sum_chatters_guests
     ,sum_listeners
     ,sum_listeners_guests
    ,openModal
    ,chatusersarray_str
    ,profiledata
    ,userdata
    ,session
    ,signIn
    ,state_following_them
    ,set_state_following_them
    ,state_following_them_status
    ,set_state_following_them_status
}) {


    var updated_chatters_guests = sum_chatters_guests

    const { isConfirmed } = useConfirm();
    const [members_chatters_set,set_members_chatters_set] = useState([members_chatters[0]?.data])
    const [members_listeners_set,set_members_listeners_set] = useState([members_listeners[0]?.data])
    const [view_users,set_view_users] = useState('chat');

    useEffect(()=>{

        let flair_json_object = flairObject(profiledata?.flair_json)
        let staff_json_object = flairObject(profiledata?.staff_json)
        let block_json_object = flairObject(profiledata?.block_json)
        let ignore_json_object = flairObject(userdata?.ignore_json)
        let approve_json_object = flairObject(profiledata?.approve_json)
    

        let uniqueSet = [];

        if (members_chatters)
        
        (members_chatters || [])
            .filter(f => f?.data?.id > 0)
            //.filter((i,index) => (index <= config.chat.maxheaderusers))
            .map(({ data: e },index) => {

                // if (e?.id == 1) console.log("e",e)

                var bol_show_name = true;
                var is_approved = 0;
                var custom_flair = e?.custom_flair || ""

                //remove this if we encounter performance issues
                if (!hasData(custom_flair)) {
                    if (profiledata?.bol_flair?.toString() == "1" 
                    && profiledata?.contributor_level > 0
                    && flair_json_object?.length > 0 
                    && flair_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase())?.length > 0) {
                      custom_flair = flair_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase())[0]?.tag?.toString()?.substring(custom_flair,0,config.chat.flair.maxtaglength)
                    }
                }

                
                var staff_flair = e?.staff_flair || ""

                //remove this if we encounter performance issues
                if (!hasData(staff_flair)) {
                    if (profiledata?.bol_staff_help?.toString() == "1" 
                    && profiledata?.contributor_level > 0
                    && staff_json_object?.length > 0 
                    && staff_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase())?.length > 0) {
                        staff_flair = "mod"
                    }
                }

                 //check to see if user matches approval list
                 if (approve_json_object?.length > 0 
                    && approve_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase() 
                                                    || f?.id?.toString()==e?.id?.toString())?.length > 0) {
                    is_approved = 1;
                }
                
                let new_data = JSON.stringify({
                     id: e?.id
                    ,user_name: e?.user_name
                    ,avatar_url: e?.avatar_url
                    ,avatar_url_sfw: e?.avatar_url_sfw
                    ,bol_nsfw: e?.bol_nsfw
                    ,avatar_crdate: e?.avatar_crdate
                    ,contributor_level: e?.contributor_level
                    ,custom_flair: custom_flair //e?.custom_flair
                    ,staff_flair: staff_flair //e?.custom_flair
                    ,type: "user"
                    ,listen: e?.listen
                    ,chat: e?.chat
                    ,approved: is_approved
                })

                //check to see if user matches staff block list
                if (bol_show_name 
                    && block_json_object?.length > 0 
                    && block_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase() 
                                        || f?.id?.toString()==e?.id?.toString())?.length > 0) {
                        bol_show_name = false;
                        updated_chatters_guests++;
                }

                //check to see if user matches personal ignore list
                if (bol_show_name 
                    && ignore_json_object?.length > 0 
                    && ignore_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase() 
                                                    || f?.id?.toString()==e?.id?.toString())?.length > 0) {
                        bol_show_name = false;
                        updated_chatters_guests++;
                }

                //do we add this?
                if (!uniqueSet?.includes(new_data) 
                    && bol_show_name) {
                        uniqueSet.push(new_data);
                }

            });

            //add bots here
            if (profiledata?.bol_bot == "1" || profiledata?.bot_announcement_bitarray > 0) {
                let bot_name = profiledata?.bot_default_name || config.chat.bot.default_name
                let bot_image = profiledata?.bot_default_image || config.chat.bot.default_image
                var custom_flair = ""

                //remove this if we encounter performance issues
                if (!hasData(custom_flair)) {
                    if (profiledata?.bol_flair?.toString() == "1" 
                    && profiledata?.contributor_level > 0
                    && flair_json_object?.length > 0 
                    && flair_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==bot_name?.toString()?.toLowerCase())?.length > 0) {
                      custom_flair = flair_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==bot_name?.toString()?.toLowerCase())[0]?.tag?.toString()?.substring(custom_flair,0,config.chat.flair.maxtaglength)
                    }
                }

                 let bot_data = JSON.stringify({
                    id: 0
                    ,user_name: bot_name
                    ,avatar_url: bot_image
                    ,avatar_url_sfw: config.chat.bot.default_image
                    ,bol_nsfw: 0
                    ,avatar_crdate: ""
                    ,contributor_level: 0
                    ,custom_flair: custom_flair //e?.custom_flair
                    ,staff_flair: "" //e?.custom_flair
                    ,type: "bot"
                    ,listen: false
                    ,chat: false
                    ,approved: 1
                });

                uniqueSet.push(bot_data)
            }
               

        set_members_chatters_set(uniqueSet);
    },[
        members_chatters,
        profiledata,
        userdata
    ])

    
    useEffect(()=>{

        let flair_json_object = flairObject(profiledata?.flair_json)
        let staff_json_object = flairObject(profiledata?.staff_json)
        let approve_json_object = flairObject(profiledata?.approve_json)
        let uniqueSet = [];

        if (members_listeners)
        
        (members_listeners || [])
            .filter(f => f?.data?.id > 0)
            //.filter((i,index) => (index <= config.chat.maxheaderusers))
            .map(({ data: e },index) => {

                //if (e?.id == 1) console.log("e",e)

                
                var custom_flair = e?.custom_flair || "";
                var is_approved = 0;

                //remove this if we encounter performance issues
                if (!hasData(custom_flair)) {
                    if (profiledata?.bol_flair?.toString() == "1" 
                    && profiledata?.contributor_level > 0
                    && flair_json_object?.length > 0 
                    && flair_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase())?.length > 0) {
                      custom_flair = flair_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase())[0]?.tag?.toString()?.substring(custom_flair,0,config.chat.flair.maxtaglength)
                    }
                }

                
                var staff_flair = e?.staff_flair || ""

                //remove this if we encounter performance issues
                if (!hasData(staff_flair)) {
                    if (profiledata?.bol_staff_help?.toString() == "1" 
                    && profiledata?.contributor_level > 0
                    && staff_json_object?.length > 0 
                    && staff_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase())?.length > 0) {
                        staff_flair = "mod"
                    }
                }
                                 //check to see if user matches approval list
                 if (approve_json_object?.length > 0 
                    && approve_json_object?.filter(f=>f?.name?.toString()?.toLowerCase()==e?.user_name?.toString()?.toLowerCase() 
                                                    || f?.id?.toString()==e?.id?.toString())?.length > 0) {
                    is_approved = 1;
                }

                let new_data = JSON.stringify({
                     id: e?.id
                    ,user_name: e?.user_name
                    ,avatar_url: e?.avatar_url
                    ,avatar_url_sfw: e?.avatar_url_sfw
                    ,bol_nsfw: e?.bol_nsfw
                    ,avatar_crdate: e?.avatar_crdate
                    ,contributor_level: e?.contributor_level
                    ,custom_flair: custom_flair //e?.custom_flair
                    ,staff_flair: staff_flair //e?.custom_flair
                    ,type: "user"
                    ,listen: e?.listen
                    ,chat: e?.chat
                    ,approved: is_approved
                })

                if (!uniqueSet?.includes(new_data)) {
                    uniqueSet.unshift(new_data)
                }

            });

            

        set_members_listeners_set(uniqueSet);
    },[
        members_listeners
    ])

    // const channel_id = `${ssr_data_tribe?.brand_id}-${config.websockets.listen}${profiledata?.id}`
    // const ably = useContext(PushContext);
    // const channel = ably?.channels?.get(channel_id)

    // channel.presence.get(function(err, members) {
    // //console.log("inside function",members_internal)
    // console.log("did it work??",members?.items,channel_id)
    // })

    const followHandler = async (id,bol_following,user_name) => {

        var confirmed = true;
        if (bol_following) {
          //do confirmation check
          confirmed = await isConfirmed(`Are you sure you want to unfollow ${user_name}?`);
        }
        
        if (confirmed) {
          if (hasData(id)) {
            const res = await fetch(`/api/private/user-follow/insert?id=${id}`);;
            const json = await res.json()
            if (json) {
              // console.log("json",json)
              const { action, status } = json

              set_state_following_them_status(status);
             
              if (action>=1) { 
                await set_state_following_them(true)
                toast(`You are now following ${user_name}!`, { time: 3000, className: '', clickable: true, clickClosable: false });
              } else { 
                await set_state_following_them(false)
              } 
            }
          }
        }
        
      }


    return (<>
                        

                        <div className=" flex flex-col w-full "
                        
                        >


                            <div className="sticky top-0 z-10">
                                
                                <div className="flex-1 justify-center w-full flex ">
                                    {validTipJar(profiledata) &&
                                    <div className="flex-1 p-1 border-b border-gray-700 bg-gray-900">
                                    
                                    
                                    <ProfileMenuTipJarNew
                                        profiledata={profiledata}
                                        preview={true}
                                    />
                                    
                                
                                    </div> 
                                    }
                                </div>

                                {!state_following_them &&
                                <div className="flex">
                                
                                    <ProfileFollowChat
                                        classColor={`${state_following_them ? 'bg-gray-500' : 'bg-brand hover:bg-blue-600'}  text-white`}
                                        onClick={()=>
                                            {
                                                if (!session) {
                                                    signIn()
                                                } else {
                                                    followHandler(profiledata?.id,(state_following_them && state_following_them_status >= 1),profiledata?.user_name)
                                                }
                                                
                                            }}
                                        message={(state_following_them && state_following_them_status >= 1) 
                                                ? `Following` 
                                                : state_following_them && state_following_them_status < 1
                                                ? 'Requested'
                                                : 'Follow'
                                                } 
                                        shrink={false}
                                    />
                                  
                                </div>
                                }

                                {/* {profiledata?.user_name=="GraveyardGoonz" &&
                                <div className="hidden sm:block bg-gray-900">
                                    <YouTube 
                                        videoId={`4IFIS2CkE68`} 
                                        opts={{
                                            width:'100%'
                                            ,height:'100%'
                                            ,playerVars: {
                                            // https://developers.google.com/youtube/player_parameters
                                            autoplay: 1,
                                            disablekb: 1,
                                            mute: 1,
                                            }}}
                                        containerClassName={'w-full h-24 bg-black'}
                                        //onReady={(event)=>{event.target.pauseVideo()}}
                                    />
                                    <div className="text-center text-2xs pt-1 pb-2 font-extralight text-bold">
                                        <Link href="https://www.youtube.com/watch?v=4IFIS2CkE68">
                                            <a target="_blank"
                                                className="no-underline hover:underline"
                                            >watch on YouTube</a>
                                        </Link>
                                    </div>
                                </div>
                                } */}

                                {/* {profiledata?.user_name?.toLowerCase()=="iregretjumping" &&
                                <div className="hidden sm:block bg-gray-900">
                                    

                                    <div style={{paddingBottom: '56.25%', position: 'relative'}}>
                                        <iframe 
                                            width="100%" 
                                            height="100%" 
                                            src="https://player.twitch.tv/?autoplay=false&channel=iregretjumping&parent=prankcast.com" 
                                            frameBorder="0" 
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"  
                                            style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%'}}
                                        />
                                        </div>

                                    <div className="text-center text-2xs pt-1 pb-2 font-extralight text-bold">
                                        <Link href="https://www.twitch.tv/iregretjumping">
                                            <a target="_blank"
                                                className="no-underline hover:underline"
                                            >watch on Twitch</a>
                                        </Link>
                                    </div>
                                </div>
                                }  */}


                                <div className="flex items-center content-center  shadow-sm md bg-gray-900">
                                
                                    <div className={`italic text-xs  flex-1 flex items-center content-center divide divide-gray-700
                                    
                                    
                                    `}
                                        
                                    >
                                        <div
                                            onClick={()=>set_view_users('chat')}
                                            className={` p-1 text-center flex-1  cursor-pointer
                                                        ${view_users=="chat" 
                                                        ? "bg-gray-800 " 
                                                        : " "}`}
                                        >{calculateUsers(members_chatters.length,profiledata)} chatting</div>
                                        
                                    </div>
                                    {hasData(profiledata?.current_listeners) &&
                                    <div className="italic text-xs flex-1 "
                                        
                                    >
                                        {/* {profiledata?.current_listeners} listening */}
                                        
                                        <div
                                            onClick={()=>set_view_users('listen')}
                                            className={` px-2 py-1 text-center cursor-pointer
                                                        ${view_users=="listen" 
                                                        ? "bg-gray-800 " 
                                                        : " "}`}
                                        >{members_listeners.length} listening</div>
                                    </div>
                                    }
                                </div>
                            </div>

                            <MembersList
                                members_set={view_users == "chat" 
                                            ? members_chatters_set 
                                            : members_listeners_set} //members_listeners_set
                                openModal={openModal}
                                sum_guests={view_users == "chat" 
                                            ? calculateUsers(members_chatters.length,profiledata) - members_chatters_set?.length
                                            : members_listeners?.length - members_listeners_set?.length} //calculateUsers(profiledata?.current_listeners,profiledata) - members_listeners_set?.length}
                                profiledata={profiledata}
                                type={view_users == "chat" 
                                            ? "chat" 
                                            : "listen"} // "listen"
                            />
                                
                        </div>
    </>)


}

const MembersList = ({
    members_set,
    openModal,
    sum_guests,
    profiledata,
    type
}
) => {

    
    return (<>
    <div className="px-1">
                            
                        
                            {members_set?.map((e,index) => {

                               
                                
                            let p = (typeof e === 'string' || e instanceof String)
                                    ? hasData(e)
                                        ? JSON.parse(e?.toString())
                                        : []
                                    : e


                            return (<Fragment key={index}>
                               
                                <div 
                                   
                                    className={` ${type=="chat" || p?.listen ? "opacity-100" : "opacity-50"} mt-1.5  hover:bg-gray-700 rounded-md cursor-pointer`}
                                    onClick={()=> { if (p?.type=="bot") {
                                        alert("This is just a bot.")
                                    } else {
                                        openModal(p?.user_name,"single","users",1,"Individual User") 
                                    }}}
                                    data-tip={p?.user_name}
                                    data-for={`chatusers`}
                                    
                                    title={p?.user_name}
                                >
                                   
                                        <div
                                            className=' flex items-center content-center
                                            
                                                '
                                          
                                            >
                                            <div className="w-8 h-8">
                                            <AvatarFlairClean              
                                                isPlaying={p?.stream_status == '1'}
                                                isContributor={p?.contributor_level > 0}
                                                isBot={["server","bot"].indexOf(p?.type?.toString()) > -1}
                                                avatar_url={chatauthor(p,`avatar_url`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                                avatar_url_sfw={chatauthor(p,`avatar_url_sfw`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                                bol_nsfw={chatauthor(p,`bol_nsfw`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                                avatar_crdate={chatauthor(p,`avatar_crdate`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                                id={chatauthor(p,`id`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                                user_name={chatauthor(p,`user_name`,profiledata?.bot_default_name,profiledata?.bot_default_image)}
                                                bgClass='w-8 h-8'
                                                containerClass='w-8 h-7 '
                                                avatarClass='w-8 h-8 border-2 border-transparent '
                                                rotaryContainerClass='w-8 h-8 mr-4'
                                                rotaryInternalClass='w-8 h-9'
                                                sizes={{
                                                  width:36
                                                 ,height:36
                                                 ,quality:50
                                                }}
                                            />
                                            </div>
                                            <div className="ml-2.5 text-sm truncate max-w-48 overflow-x-hidden">
                                                <div className="font-bolder pb-1 text-white">
                                                   <div className="flex items-center content-center">
                                                      <div className="">
                                                            {p?.user_name} 
                                                      </div>
                                                      
                                                        {(p?.id == profiledata?.id || p?.staff_flair?.length > 0 || p?.approved?.toString() == "1") &&
                                                        <div className="ml-1">
                                                            <SvgJsx 
                                                                type={'outline'}
                                                                icon={'check'}
                                                                className={` h-2 w-2 text-white opacity-70`}
                                                                title={'Approved chatter'}
                                                            />
                                                        </div>
                                                        }
                                                      
                                                   </div> 
                                                </div>
                                                {(p?.id == profiledata?.id ||
                                                p?.staff_flair?.length > 0 ||
                                                p?.type == "bot" ||
                                                p?.custom_flair?.length > 0 ) &&
                                                <div className=" -mt-2 flex items-center content-center">
                                                    

                                                    {p?.id == profiledata?.id &&
                                                    <ChatFlair
                                                        text={`host`}
                                                        bgcolor={`bg-red-600`}
                                                    />
                                                    }

                                                    {p?.staff_flair?.length > 0 && (p?.id !== profiledata?.id) &&
                                                    <ChatFlair
                                                        text={`mod`}
                                                        bgcolor={`bg-red-600`}
                                                    />
                                                    }

                                                    {p?.type == "bot" &&
                                                    <ChatFlair
                                                        text={`bot`}
                                                        bgcolor={`bg-green-600`}
                                                    />
                                                    }
                                                
                                                    {p?.custom_flair?.length > 0 &&
                                                    <ChatFlair
                                                        text={p?.custom_flair}
                                                        bgcolor={`bg-blue-600`}
                                                    />
                                                    }
                                                </div>
                                                }


                                               
                                            </div>
                                            
                                        </div>
                                        
                                
                                    
                                </div>
                              
                                
                                </Fragment>)
                            })}

                            {sum_guests > 0 &&
                            <div className="ml-2 mt-1 text-sm italic">
                                + {sum_guests} {formatPlural("guest",sum_guests)}
                            </div>
                            }

                            
                            </div>
    </>)
}

export default ChatUsersArrayVertical