
import { config } from "@/lib/config";
// import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import SvgJsx from "../templateux/svg/svg-jsx";
import { signIn } from "next-auth/client"
import { useEffect, useState } from "react";

export default function ChatForm({
    
     msg
    ,set_msg
    ,handleFormSubmission
    ,session
    ,loading
    ,userdata
    ,userdataloading
    ,sendmsg
    ,channel_id
    ,profiledata
    ,openModalChooser
    ,inputRef
    ,time
    ,show_users
    ,show_chats
    ,commenting
    ,set_commenting
    ,placeholder_text

}) {

    useEffect(()=>{
        if (typeof window !== "undefined" && inputRef?.current) inputRef.current.focus();
    },[])



    
    //  const { isConfirmed } = useConfirm();
    
    return (<>
    <form 
        onSubmit={handleFormSubmission} 
        id={`msg`}
        name={`msg`}
    >
        <div className={`flex items-center content-center
                            ${msg?.bol_private == 1 
                                ? `
                                    bg-red-700 text-white placeholder-red-500  `
                                : `bg-gray-700 text-white placeholder-gray-500`}
                                `}
        
        >
            <div className={`group flex items-center content-center `}  
                    

                        
            >
                <a
                    role="button"  
                    tabIndex={0}  
                    className={`cursor-pointer font-bold  flex pl-1.5 pr-1.5 py-3 h-12  
                    ${!show_chats ? "rounded-bl-md" : ""}
                        opacity-80 group-hover:opacity-100 group-hover:text-white
                        `}
                    
                    onClick={()=> {
                            if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                                signIn();
                                return;
                            }
                            set_msg({...msg,bol_private:msg.bol_private==0 ? 1 : 0});
                            inputRef.current.focus();
                        }}
                        
                    onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                        if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                            signIn();
                            return;
                        }
                        set_msg({...msg,bol_private:msg.bol_private==0 ? 1 : 0});
                        inputRef.current.focus();
                    }}}

                    // onClick={async ()=> {
                    //     const confirmed = await isConfirmed({
                    //         title: `This feature is temporarily unavailable`,
                    //         body: <>Private messages are temporarily disabled for security maintenance. 
                    //                 This feature will be restored soon.
                    //                 If you need to send a user a message in the mean time, please use Discord or another method until this is back.
                    //             </>,
                    //         confirm:``,
                    //         cancel:`Close`,
                    //         show_title: true,
                    //         show_cancel: true,
                    //         show_confirm: false
                    //       });
                    //     if (confirmed) { await inputRef.current.focus(); }
                    // }}
                >
                    <SvgJsx 
                        type={msg?.bol_private==1 ? `fill` : `outline`} 
                        icon={msg?.bol_private==1 ? `lock-closed-sm` : `lock-open`} 
                        className={` w-5 h-5 flex-0 my-auto 
                        `}
                        title={msg?.bol_private==1 ? `Private message` : `Public message`} 
                    />
                </a>
            </div>
            <div className="flex-1  w-full transition  transition-slowest ease duration-100">
                <input name="chrome-autofill-dummy1" type='hidden' className="hidden" disabled={true} />
                <input name="chrome-autofill-dummy2" type='hidden' className="hidden" disabled={true} />
                <div className="border-b border-gray-700 relative"
                    onClick={()=>{ if (!loading && !userdataloading && session && userdata?.id?.toString()!=="0") inputRef?.current?.focus();}}
                >
                    {(!loading && !userdataloading && session && userdata?.id?.toString()!=="0") &&
                    <>
                    <input
                        tabIndex={0}
                        ref={inputRef}
                        type="text"
                        name="msgtext"
                        id="msgtext"
                        autoFocus={true}
                        autoComplete="off"
                        maxLength={config.chat.maxmessagelength}
                        onClick={()=>  {

                            if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                                signIn();
                                return;
                            }
                            inputRef.current.focus();
                        }}
                       
                        onChange={(e)=> {
                            if (e.target.value.length > 0) {
                                set_commenting(true)
                            } else {
                                set_commenting(false)
                            }
                        }}
                        placeholder={placeholder_text}
                        className={`w-full h-12 flex flex-1 border border-transparent pl-0.5 focus:border-transparent focus:ring-0 focus:ring-offset-0 focus:ring-white
                                    ${msg?.bol_private == 1 
                                    ? `
                                        bg-red-700 text-white placeholder-red-500`
                                    : `bg-gray-700 text-white ${placeholder_text.indexOf('restricted') > -1 ? "placeholder-red-300/30" : "placeholder-gray-500"}`}
                                    cursor-pointer
                                    absolute -mt-6
                                    `}
                    />
                        {inputRef?.current?.value?.length == 0 &&
                            <div 
                                className="w-full absolute bg-transparent cursor-pointer h-20 -mt-6 xs:hidden" 
                                onClick={()=>  {

                                if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                                    signIn();
                                    return;
                                }
                                inputRef.current.focus();
                            }}
                            />
                        }
                    </>
                    }
                    {(loading || userdataloading || !session || userdata?.id?.toString()=="0") &&
                    <div
                       role="button"
                       title="Send"
                       tabIndex={0}
                        onClick={()=>signIn()}
                        onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) signIn()}}
                        
                        className={`w-full shadow-md flex items-center content-center flex-1 border border-transparent h-12 pl-2 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-red-400
                        bg-gray-700 text-gray-500 hover:text-gray-200
                         cursor-text
                        `}
                    >
                        <div>
                            {(loading || userdataloading)
                            ? `Authorizing... hang on...`
                            : `Login to post in chat...`
                            }
                       </div>
                    </div>
                    }
                </div>
            </div>
            
            <div className="flex flex-0">
                <a
                    className={`cursor-pointer h-12  text-xl  font-bold group
                    ${msg?.bol_private == 1 
                        ? ` text-white  `
                        : ` text-white  `}


                             ${commenting 
                                ? "flex"
                                : "hidden "
                            }
                    `} 
                    onClick={handleFormSubmission}
                    onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) handleFormSubmission()}}
                
                    role="button"
                    tabIndex={0}
                    title="Send"
                >
                    <div 
                        className="flex items-center content-center"
                        
                    >
                      
                        <div className="flex-0">
                            <SvgJsx 
                                type={'fill'}
                                icon={'check-circle-sm'}
                                className={` h-10 w-10 group-hover:opacity-80 group-hover:animate-wiggle`}
                                title={'SEND'}
                            />
                        </div>
                    </div>
                    
                </a>
            </div>
            
            <div className={`flex flex-0
            ${commenting
                ? "xs:hidden block"
                : "hidden"
            }
            
            `}>
                <a
                    className={`cursor-pointer h-12  flex text-xl  font-bold

                   
                   
                    `}  
                    onClick={()=>set_commenting(false)}
                    onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) set_commenting(false)}}
                    title="Collapse"
                    role="button"
                    tabIndex={0}
                >
                    <div 
                        className="flex items-center content-center"
                        
                    >
                      
                        <div className="flex-0 pr-0.5">
                            <SvgJsx 
                                type={'fill'}
                                icon={'chevron-left-sm'}
                                className={` h-7 w-7`}
                                title={'Collapse'}
                            />
                        </div>
                    </div>
                    
                </a>
            </div>
            
            <div className={`flex flex-0 items-center content-center group relative
                ${!commenting 
                    ? "block"
                    : "hidden xs:block"
                }
            
            `}>
                <a 
                    title="Chat Emoji"
                    tabIndex={0}
                    role="button"
                    className={` flex flex-0 items-center content-center
                    ${(!show_users && ["1","2"].indexOf(profiledata?.bol_chat_image?.toString()) == -1) 
                    ? "rounded-br-md" 
                    : ""}
                    cursor-pointer h-12 flex text-3xl  font-bold   py-1 group
                      `}
                    onClick={async (e) => {
                        if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                            signIn();
                            return;
                        }
                        
                        sendmsg(e,channel_id,"message",{...msg,emoji:1,text:`${profiledata?.chat_emoji}`},'')}}
                    onKeyDown={async (e)=> {
                        if ([13,32].indexOf(e.keyCode) > -1) {
                            if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                                signIn();
                                return;
                            }
                            sendmsg(e,channel_id,"message",{...msg,emoji:1,text:`${profiledata?.chat_emoji}`},'')}
                        }}
                >
                    <div className="flex-0 items-center content-center group-hover:opacity-80 group-hover:animate-wiggle
                  ">
                    {profiledata?.chat_emoji}
                    </div>
                </a>

                {/* POINTS - */}
                {/* {session &&
                        <div className={` hidden group-hover:flex items-center content-center absolute  
                                    -top-[60px] 
                                    flex-0 
                                    bg-gray-600
                                    -right-[28px]
                                        rounded-md
                                        overflow-hidden
                                        shadow-md
                                        
                                    `}
                                    >
                                        
                    
                            
                            <div className=" text-center text-sm h-16">
                                <div className="text-3xs whitespace-nowrap h-6">
                                    <div className="flex items-center content-center mx-1">
                                        <div className="flex-1 text-left text-xs font-bold mr-2">
                                            Award points
                                        </div>
                                        <div>
                                        69 left
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className=" flex-1 flex items-center content-center mx-2">
                                    <div className="flex-1"></div>
                                    <div className="flex flex-0 items-center content-center gap-x-2 text-sm ">
                                        
                                        {[1,2,3].map((e)=> {
                                            return (<div 
                                                        key={e}
                                                            className="hover:bg-gray-700 rounded-sm px-2 py-1 cursor-pointer">
                                                        <div className="text-lg font-bold">{e}</div> 
                                                    </div>)
                                        })}
                                        

                                    </div>
                                </div>
                            </div>
                    
                    
                        </div>
                } */}

            </div>
            
            {(["1","2"].indexOf(profiledata?.bol_chat_image?.toString()) > -1) && 
            <div className={`flex flex-0
                ${!commenting 
                    ? "block"
                    : "hidden xs:block"
                }
            
            `}>
                <a
                    role="button"
                    title="Images"
                    tabIndex={0}                    
                    className={`
                    ${!show_users 
                        ? "rounded-br-md" 
                        : ""}
                        cursor-pointer h-12 flex text-3xl  font-bold  px-0.5 py-1
                        text-white
                         
                         group`} 
                    onClick={async (e) => {
                        if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                            signIn();
                            return;
                        }
                        openModalChooser("gifs")
                    }}
                    onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                        if (loading || userdataloading || !session || userdata?.id?.toString()=="0") {
                            signIn();
                            return;
                        }
                        openModalChooser("gifs")
                    }}}
                >
                    <div className="flex items-center content-center">
                        
                        <div className="flex-0 ">
                            <SvgJsx 
                                type={'fill'}
                                icon={'photograph-sm'}
                                className={` h-9 w-9 group-hover:animate-wiggle group-hover:opacity-80`}
                                title={'images'}
                            />
                        </div>
                    </div>
                </a>
            </div>
            }
        </div>
          
        </form>
    </>)
}